<template>
       <div class="bg-noise">
        <header>
            <div class="logo">
                <picture>
                    <source media="(min-width: 1025px)" srcset="@/assets/logo.png">
                    <img src="@/assets/logo-02.png" alt="">
                </picture>
            </div>
            <div class="menu" :class="{'open' : open}">
                <ul>
                    <!--<li>
                        <a @click="closeMenu" class="menu-links" href="#top">Home</a>
                    </li>-->
                    <li>
                        <a @click="closeMenu" class="menu-links" href="#about">Sobre</a>
                    </li>
                    <li>
                        <a @click="closeMenu" class="menu-links" href="#who-use">Quem usa</a>
                    </li>
                    <li>
                        <a @click="closeMenu" class="menu-links" href="">Contrate</a>
                    </li>
                    <li>
                        <a @click="closeMenu" class="menu-links" href="#faq">FAQ</a>
                    </li>

                    <li>
                        <div class="social-links mobile">
                            <a href="https://www.facebook.com/methoduzapp/" target="_blank">
                                <i class="fa-brands fa-facebook"></i>
                            </a>

                            <a href="https://www.instagram.com/methoduzapp/" target="_blank">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="demo" @click="openMenu">
                <div class="menu-icon">
                    <input class="menu-icon__cheeckbox" type="checkbox"/>
                    <div>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>

            <div class="social-links">
                <a href="https://www.facebook.com/methoduzapp/" target="_blank">
                    <i class="fa-brands fa-facebook"></i>
                </a>

                <a href="https://www.instagram.com/methoduzapp/" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                </a>
            </div>
        </header>

        <section id="home">
            <div class="range"></div>

            <h2 id="title-message"></h2>

            <div class="photo">
                <img class="panel" :src="images[count]" alt="">
                <img class="woman" src="@/assets/mulher-01.png" alt="">
            </div>
        </section>
       </div>
    <section id="about"></section>

    <section>
        <div class="about-one bg-noise">
            <div class="text">
                <div>
                    <h2>O que é o</h2>
                    <img src="@/assets/logo.png" alt="">

                    <div>
                        <p>Esta plataforma torna o envio de anúncios mais ágil para os painéis e TVs indoor da sua
                            empresa. Ideal para lotéricas, clínicas, restaurantes e áreas corporativas. Tenha seu canal
                            interno, publique resultados de jogos, previsão do tempo, tudo atualizado em tempo real,
                            enviado diretamente do seu telefone celular ou computador!</p>
                    </div>
                </div>
            </div>

            <div class="mockup">
                <img src="@/assets/mockup-01.png" alt="">
                <div class="icons">
                    <div class="box">
                        <img src="@/assets/icons-01.png" alt="">
                        <p>Plataforma <br> <strong>100% em nuvem</strong></p>
                    </div>

                    <div class="box">
                        <img src="@/assets/icons-02.png" alt="">
                        <p>Atualização <br> em tempo real</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="about-two bg-noise">
            <div class="range"></div>
            <div class="photo">
                <img src="@/assets/telas-02.png" alt="">
            </div>

            <div class="text">
                <h2>Methoduz é digital <br> Signage?</h2>

                <p>O termo <strong>"digital signage"</strong> refere-se a displays eletrônicos de todas as dimensões,
                    tais como painéis de LEDs, TVs, menu board. monitores e outros. O objetivo é promover de uma forma
                    dinâmica e envolvente por meio de imagens ou vídeos, capazes de melhorar, motivar e impactar seus
                    clientes.</p>

                <p>Esse tipo de comunicação está presente em diversos lugares, como lojas, supermercados, farmácias
                    restaurantes, escolas e elevadores.</p>

                <p>Assim como você se lembrou do que é e onde viu, sua empresa também pode ser lembrada pelos seus
                    clientes</p>
            </div>
        </div>
    </section>

    <section>
        <div class="about-three bg-noise">
            <div class="photo">
                <img src="@/assets/pendrive.png" alt="">
            </div>

            <div class="text">
                <h2>Adeus aos <br> pendrives</h2>

                <p>Deixe o receio de ser esquecido para trás e de as boas-vondas ao sucesso com a sinalização digital,
                    utilizando a tecnologia Methoduz.</p>

                <p>Solução completa, atrativa, rápida e eficiente!</p>

                <p>Deixe de carregar pendrives com promoções e ofertas.</p>

                <p>Carregue vídeos e campanha inteiras com apenas um clique!</p>
            </div>
        </div>
    </section>

    <section>
        <div class="about-four bg-noise">
            <div class="photo">
                <div class="square"></div>
                <img class="man" src="@/assets/homem-01.png" alt="">
                <img class="woman" src="@/assets/mulher-02.png" alt="">
            </div>

            <div class="text">
                <h2>É possível para <br> o meu negócio? </h2>

                <p>A plataforma foi desenvolvida para atender a diversos setores, tais como lotéricas, shoppings,
                    hospitais, clínicas, açougues, restaurantes, mercados, empresas multinacionais e muito mais.</p>

                <p>Estamos disponíveis para atender a todos, não importa o tamanho da sua empresa.</p>

                <p>A TV Corporativa é uma ferramenta capaz de otimizar, influenciar, motivar e impactar seus
                    clientes.</p>

                <div class="box">
                    <div class="icons">
                        <img src="@/assets/icons-03.png" alt="">
                    </div>
                    <div class="icons">
                        <img src="@/assets/icons-04.png" alt="">
                    </div>
                    <div class="icons">
                        <img src="@/assets/icons-05.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="about-six bg-noise">
            <div class="square"></div>
            <div class="text">
                <h2>Uma assinatura <br> para diversos <br> usuários</h2>
            </div>
            <div class="image">
                <img src="@/assets/player.png" alt="">
            </div>
            <div class="text two">
                <div class="box">
                    <p>Nos supermercados, há diversas áreas, como açougue, hortifrúti e clube de vantagens e cada
                        responsável consegue manter o seu setor atualizado com vídeos e artes. </p>

                    <p>Agilize cada área com suas ofertas!</p>
                </div>
            </div>
            <div class="square two"></div>
        </div>
    </section>

    <section>
        <div class="about-seven bg-noise">
            <div class="text">
                <h2>Interface</h2>
                <p>Simples, fácil e intuitiva.</p>

                <div class="icon">
                    <img src="@/assets/icons-06.png" alt="">
                    <p>Aprenda em <br> minutos</p>
                </div>
            </div>
            <div class="mockup">
                <img src="@/assets/mockup-02.png" alt="">
            </div>

            <div class="range">
                <p>Aprenda a utilizar a plataforma em apenas alguns minutos.</p>
                <br>
                <p>Com uma interface simples e intuitiva, você pode gerenciar diversos players de mídias pelo celular,
                    computador e tablet.</p>
            </div>
        </div>
    </section>

    <section>
     <div class="about-eight bg-noise">
            <div class="text-mobile">
                <h2>Grade de <br> anúncios</h2>

                <p>Crie sua grade de anúncios e controle o que está sendo exibido nas suas telas. Selecione a tela
                    conforme as suas necessidades.</p>
            </div>
            <div class="square">
                <p>Grade de <br> Anúncios</p>
            </div>
            <div class="image">
                <img src="@/assets/mulher-03.png" alt="">
            </div>
            <div class="square-text">
                <p>Crie sua grade de anúncios e controle o que está sendo exibido nas suas telas. Selecione a tela
                    conforme as suas necessidades.</p>
            </div>
        </div>
    </section>

    <section>
        <div class="about-nine bg-noise">
            <h2>Gerenciamento multiplayer</h2>
            <p>Gerencie diversos players utilizando apenas um dispositivo móvel.</p>
            <p>Escolha as artes e para qual TV quer enviar.</p>
            <p>Maior simplificação na sua rotina de anúncios.</p>

            <div class="image">
                <img src="@/assets/gerenciamento.png" alt="">
            </div>
        </div>
    </section>

    <section id="who-use" class="bg-noise">
        <div class="square"></div>

        <div class="text">
            <h2>Quem <br> usa</h2>

            <p>O Methoduz é utilizado por empresas de diversos seguimentos. Temos soluções para mídia corporativa, menu
                board, mídia indoor e vitrine digital</p>
        </div>

        <div class="customers">
            <div class="square-two"></div>
            <div class="box">
                <div class="logo">
                    <img src="@/assets/logos-01.png" alt="">
                </div>
                <div class="logo">
                    <img src="@/assets/logos-02.png" alt="">
                </div>
                <div class="logo">
                    <img src="@/assets/logos-04.png" alt="">
                </div>
                <div class="logo">
                    <img class="shell" src="@/assets/logos-03.png" alt="">
                </div>
                <div class="logo">
                    <img src="@/assets/logos-05.png" alt="">
                </div>
                <div class="logo">
                    <img src="@/assets/logos-06.png" alt="">
                </div>
            </div>
        </div>
    </section>

    <section id="hire" class="bg-noise">
        <div class="range"></div>
        <div class="text">
            <h2>Podemos <br> conversar?</h2>
        </div>

        <div class="icons">
            <a target="_blank" href="https://wa.me/5519993939090">
                <i class="fa-brands fa-whatsapp"></i>
                <p>(19) 99393-9090</p>
            </a>

            <a target="_blank" href="mailto:comercial@methoduz.com">
                <i class="fa-solid fa-envelope"></i>
                <p>comercial@methoduz.com</p>
            </a>

            <a target="_blank" href="https://www.instagram.com/methoduzapp/">
                <i class="fa-brands fa-instagram"></i>
                <p>@methoduzapp</p>
            </a>

            <a target="_blank" href="https://www.facebook.com/methoduzapp/">
                <i class="fa-brands fa-facebook"></i>
                <p>@methoduzapp</p>
            </a>
        </div>
    </section>

    <section id="faq" class="bg-noise">
        <h2>Dúvidas frequentes</h2>

        <ul class="container">
            <li class="box" v-for="(faq, index) in questions" :key="index" @click="toggleAnswer(index)">
                <div class="question">
                    <p class="question__text">{{ faq.text }}</p>
                    <i class="fa-solid fa-plus icon" :class="{active: faq.isOpen}"></i>
                </div>
                <div class="answer" :class="{ active: faq.isOpen}">
                    <p class="answer__text">{{ faq.answer }}</p>
                </div>
            </li>
        </ul>
    </section>

    <a href="https://wa.me/5519993939090" target="_blank" class="whatsapp-btn">
        <i class="fa-brands fa-whatsapp"></i>
    </a>

    <footer>
        Todos os direitos reservados Methoduz
    </footer>
</template>

<script>
import Typed from "typed.js";
import {questions} from "@/configs/constants";

const one = require("@/assets/telas-01.png")
const two = require("@/assets/telas-03.png")
const three = require("@/assets/telas-04.png")

export default {
    name: "HomeView",

    mounted() {
        setInterval(() => {
            this.count = (this.count + 1) % 3; // Incrementa até 2 e zera
        }, 5000);

        this.title = new Typed("#title-message", {
            strings: [
                "Conectando <br> negócios",
                "Gerenciamento <br> de multiplayer",
                "Atualização em <br> Tempo real",
                "Plataforma 100% <br> na nuvem",
            ],
            loop: true,
            typeSpeed: 50,
            showCursor: false,
        })
    },

    data() {
        return {
            open: false,
            active: false,
            title: "",

            count: 0,
            images: [
                one,
                two,
                three
            ],

            questions
        }
    },

    methods: {
        openMenu() {
            this.open = !this.open
        },

        closeMenu() {
            this.open = !this.open

            let btn = document.querySelector(".menu-icon__cheeckbox")
            if (btn.checked)
                btn.checked = false
        },

        toggleAnswer(index) {
            this.questions.forEach((question, i) => {
                if (i === index) {
                    question.isOpen = !question.isOpen;
                } else {
                    question.isOpen = false;
                }
            });
        }
    }
}
</script>

<style src="@/styles/main.css"></style>
<style src="@/styles/responsive.css"></style>
<style scoped>
#title-message::after {
    content: '|';
    opacity: 1;
    margin-left: 5px;
    display: inline-block;
    animation: blink .7s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>