export const questions = [
    {
        text: 'É necessário ter um CNPJ para contratar o METHODUZ?',
        answer: 'Não, a contratação do serviço pode ser feita por qualquer pessoa física ou jurídica.',
        isOpen: false
    },

    {
        text: 'Quais são as formas de contratações disponíveis?',
        answer: 'Você pode optar pela licença por player, ou contratar o plano comodato, que inclui o player já configurado.',
        isOpen: false
    },

    {
        text: 'Posso solicitar a aquisição do player diretamente pelo METHODUZ?',
        answer: 'Sim, enviamos para todo o Brasil com a garantia de seis meses para defeitos de fabricação.',
        isOpen: false
    },

    {
        text: 'Preciso de um computador para ter acesso à plataforma METHODUZ?',
        answer: 'Sugerimos a administração pelo computador, mas o sistema pode ser acessado por celular ou até mesmo tablet. Por ser uma plataforma web, pode ser usada de qualquer lugar do mundo.',
        isOpen: false
    },

    {
        text: 'Como é o acesso à plataforma METHODUZ?',
        answer: 'No momento da contratação, informe o e-mail para cadastro. Você receberá um e-mail com seu login e senha para acessar o sistema.',
        isOpen: false
    },

    // {
    //     text: 'Como funciona o pagamento?',
    //     answer: 'O Sistema de pagamento e pré-pago por quantidade de players, os planos podem ser mensal, semestral ou anual.',
    //     isOpen: false
    // },
    //
    // {
    //     text: 'Quais são os meios de pagamento? ',
    //     answer: 'Cartão de credito, boleto bancário ou PIX.',
    //     isOpen: false
    // },
    //
    // {
    //     text: 'Qual é o tempo de instalação e liberação do sistema?',
    //     answer: 'No plano de licenças o acesso a plataforma e liberado de imediato após o pagamento e assinatura de contrato. No plano comodato para configuração ativação do player e envio pedimos um prazo de 7 dias úteis.',
    //     isOpen: false
    // },
    //
    // {
    //     text: 'Após contratação recebo treinamento? ',
    //     answer: 'Sim, você poderá acessar os vídeos tutorias do sistema, e também marcar um treinamento com nossos especialistas remotamente.',
    //     isOpen: false
    // },
]